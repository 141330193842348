import React from 'react'
import { Link } from 'src/components/RouterDom'
import momentTZ from 'moment-timezone'
import _locale from 'locale'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _snakeCase from 'lodash/snakeCase'
import _capitalize from 'lodash/capitalize'
import _ from 'lodash'
import debug from 'debug'
import { isBrowser } from 'src/env'
import { getCentreConfig } from 'src/config'
import 'moment/locale/fr'

export * from '@redant/hammerson-plus-config/resources'

export * from './search'

const { stringify, parse } = require('flatted/cjs')

export const delay = (ms) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms)
  })
}

export const goodFetch = (url, options) => {
  const headers = {
    'content-type': 'application/json; charset=UTF-8',
    ..._get(options, 'headers')
  }
  return fetch(url, { ...options, headers })
    .then((res) => {
      if (!res.ok) {
        return res.json().then((x) => {
          throw new Error(x.message || res.status)
        })
      }
      return res
    })
    .then((res) => res.json())
}

const convertToDropdownOptions = ({ title, name, value }) => {
  if (title && value !== undefined) {
    return { label: title, value }
  } else if (title) {
    return { label: title, value: _snakeCase(title) }
  } else if (name && value !== undefined) {
    return { label: _capitalize(name), value }
  } else if (name) {
    return { label: _capitalize(name), value: _snakeCase(name) }
  } else {
    return
  }
}

export const convertResourceToDropdownOptions = (resource) => {
  const fields = _get(resource, '0.fields', {})
  const keys = Object.keys(fields) || []
  const isCategories = keys.includes('categories')
  const isShop = keys.includes('name')

  let fieldsArray = []

  if (isCategories) {
    const categoriesResources = _get(fields, 'categories', {})
    return convertResourceToDropdownOptions(categoriesResources)
  } else if (isShop) {
    fieldsArray = _map(resource, (r) => _get(r, 'fields', {}))
    return _map(fieldsArray, convertToDropdownOptions)
  } else {
    fieldsArray = _get(resource, '0', [])
    return _map(fieldsArray, convertToDropdownOptions)
  }
}

export const getIdFromParams = (props) => _get(props, 'match.params.id')
export const getFirstPath = (props) => {
  return _get(props, 'location.pathname', '').split('/')[1]
}

export const getParentCategoryAlias = (parentCategoryName) => {
  const category = parentCategoryName.toLowerCase()
  switch (category) {
    case 'dine':
      return 'Eatery'
    case 'eatery':
      return 'dine'
    case 'shop-and-dine':
      return ['Shop', 'Eatery']
    default:
      return parentCategoryName
  }
}

export const ssrStringify = (x) => encodeURI(stringify(x))

export const ssrParse = (x) => parse(decodeURI(x))

export const parseUrl = (url) => {
  let parsed = {}
  if (isBrowser) {
    if (window.URL) {
      const URL = window.URL
      parsed = new URL(url)
    } else {
      const el = document.createElement('a')
      el.href = url
      parsed = el
    }
  }
  return _.pick(parsed, [
    'hash',
    'host',
    'hostname',
    'href',
    'origin',
    'pathname',
    'port',
    'protocol',
    'search'
  ])
}

export const prepareCanonicalUrl = ({ protocol, host, url, path = null }) => {
  let canonicalUrl = path || url
  const [baseUrl, queries] = canonicalUrl.split('?')
  if (queries) {
    const queriesArray = queries.split('&')
    const queriesParamsDisallow = ['search', 'letter']
    const allowedParams = _filter(queriesArray, (sParam) => {
      const param = sParam.split('=')[0]
      return !queriesParamsDisallow.includes(param)
    })
    if (allowedParams.length > 0) {
      canonicalUrl = `${baseUrl}?${allowedParams.join('&')}` 
    } else {
      canonicalUrl = baseUrl
    }
  }
  
  return path 
    ? `${canonicalUrl}`
    : `${protocol}://${host}${canonicalUrl}`
}

export const getBestLocale = () => {
  const { defaultLocale } = getCentreConfig() || {}
  return defaultLocale || 'en-GB'
}

export const showHeartResourceTypes = ['promotion', 'news', 'event', 'product']

export const showPostDateRange = false

// use these logs for PERMANENT logs that might be useful for a developer to see.
// use console.log for temporary debugging work.
// this introduces a useful separation between the two.
// to make these logs visible in the console set localStorage.debug to ham-client:*
const log = debug('ham-client')
const logTrace = log.extend('trace')
const logError = log.extend('error')
const logInfo = log.extend('info')
export { logTrace, logError, logInfo }

export const customMoment = ({ host } = {}) => (...args) => {
  // a localised moment belongs in the internalization hook.
  // hooks aren't avaliable in getData however, so this is also avaliable.
  const centre = getCentreConfig({ host }) || { defaultLocale: 'en-GB', timezone: 'Europe/London' }
  const london = 'Europe/London'
  const fallbackTimezone = _.get(
    {
      'en-GB': london,
      fr: 'Europe/Paris'
    },
    centre.defaultLocale,
    london
  )
  return momentTZ(...args)
    .locale(centre.defaultLocale)
    .utc()
    .tz(centre.timezone || fallbackTimezone)
}

export const createFormatTime = ({ moment }) => ({
  originFormat,
  outputFormat
}) => (time) => {
  // we don't actually want to use custom moment here, we want to use
  // normal moment.
  // if we use a custom moment, then the timezone is change in addition
  // to formatting.
  const output = momentTZ(time, originFormat).format(outputFormat)
  return output
}

export const renderURLInText = ({ text, url }) => {
  const [beforeLink, linkAndAfterLink] = _.split(text, `<link>`)
  const [link, afterLink] = _.split(linkAndAfterLink, `</link>`)
  return (
    <>
      {beforeLink}
      {link && url && (
        <Link style={{ whiteSpace: 'nowrap' }} to={url}>
          {link}
        </Link>
      )}
      {afterLink}
    </>
  )
}

export const replaceWords = ({
  input,
  separators = [],
  replacer = () => { }
}) => {
  if (!input) return ''
  let munged = ''
  const chars = _.split(input, '')
  let word = ''
  let wordCount = 0
  chars.forEach((char) => {
    if (separators.includes(char)) {
      if (word) {
        munged += replacer(word, wordCount)
        wordCount++
      }
      word = ''
      munged += char
    } else {
      word += char
    }
  })
  if (word) munged += replacer(word)
  return munged
}

export const getReferencedOpeningTimes = (referencedOpeningTimesObject) => {
  return _get(referencedOpeningTimesObject, 'fields.openingHours')
}
