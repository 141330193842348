import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { isBrowser, isCordova, isMobileDevice } from 'src/env'
import contentfulService from 'src/services/contentfulService'
import { useInternationalisation } from 'src/context'
import useCookies from 'src/hooks/useCookies'
import { useCentre } from 'src/store/resources/hooks'
import { ToggleButton } from 'src/components/ToggleButton'
import media from 'src/styling/media'
import CookieSvg from 'src/assets/Cookie.svg'
import AssetIcon from 'src/components/AssetIcon'
import useFacebookPixel from 'src/hooks/useFacebookPixel'
import DotDigitalPopup from 'src/components/DotDigitalPopup'

const CookiePolicyOuter = styled.aside`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: ${(p) => p.isModalOpen ? 1000000 : 1};
`

const CookiePolicyInner = styled.div`
  z-index: 10;
  width: 500px;
  overflow: auto;
  background-color: rgb(38, 42, 48);
  font-family: "Poppins", Arial;
  color: white;
  display: flex;
  flex-direction: column;
  transition: .4s;
  position: fixed;
  ${(p) => p.customizeCookiesToggleOn && `height: 100%;`}
  max-height: 100%;
  left: ${(p) => p.isModalOpen ? 0 : '-500px'};
  bottom: 0;
  padding: ${isMobileDevice ? '20px 20px 60px 20px' : '20px 20px 40px 20px'};;
  padding-left: max(20px, env(safe-area-inset-left));
  overscroll-behavior-y: contain;

  ${media.lessThan('md')`
    width: calc(100vw - 25px);
    left: ${(p) => p.isModalOpen ? 0 : 'calc(25px - 100vw)'};
  `}
`

const CookieHeader = styled.div`

`

const CookieHeaderText = styled.h2`
  margin: 0 0 1em 0px;
  font-size: 1.2em;
`

const CookieUsageInformation = styled.div`

`

const CookieSubSectionWrapper = styled.div`
  position: relative;
`

const CookieParagraph = styled.div`
  margin: 0 0 1em 0px;
  font-size: 1em;
  > div > p, h4 {
    color: white;
  }
  > div > h4 {
    font-weight: 500;
    font-size: 1.2em;
  }
`

const CookieContent = styled.div`
`

const CookieFooter = styled.div`
  display: flex;
  flex-direction: row;

  ${media.lessThan('md')`
    flex-direction: column;
  `}
`

const CookieActionButton = styled.button`
  background-color: ${({ buttonType }) => buttonType === 'primary' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'};
  color: ${({ buttonType }) => buttonType === 'primary' ? 'rgb(38, 42, 48)' : 'rgb(255, 255, 255)'};;
  padding: 1em;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  margin: 0 10px 0 0;
  display: block;
  margin-bottom: 12px;
  width: 300px;

  ${media.lessThan('md')`
    margin:  0 0 10px 0;
    width:100%;
  `}

  &:hover {
    background-color: ${({ buttonType }) => buttonType === 'primary' ? 'rgba(255, 255, 255, 0.9)' : 'rgb(0, 0, 0, 0.28)'};
  }

`

const CookieDrawerToggleButton = styled.button`
 position: fixed;
 bottom: 0;
 left: 0;
 z-index: 11;
`

const SeparateLine = styled.hr`
  margin: 20px 0;
`

const Overlay = styled.div`
  background-color: rgb(0 0 0 / 39%);
  width: 100%;
  height: 100%;
  position: fixed;
`

const CookiePolicyWidgetLarge = () => {
  const { translate, translateUrl } = useInternationalisation()
  const [state, actions] = useCookies()
  useFacebookPixel(state.personalisedAdCookiesOptedIn)
  const centre = _.get(useCentre(), 'fields', {})
  const {
    cookieOptInText,
    cookieOptInButtonText,
    cookieRejectAllButtonText,
    necessaryCookiesText,
    cookiePreferencesText,
    customiseCookiesPreferenceHeading,
    audienceMeasurementText,
    personalisedAdsText,
    customiseNowButtonText,
    saveMyPreferenceButtonText,
    enableDotDigitalPopup,
    popUpCode
  } = centre

  const cookiePolicyLink = `/${translateUrl('cookie-policy')}`

  const [isModalOpen, setModal] = useState(!state.userSetPreference)
  const toggleModal = () => {
    setModal(!isModalOpen)
    setCustomizeCookies(false)
  }

  const [customizeCookiesToggleOn, setCustomizeCookies] = useState(false)
  const toggleCustomizeCookies = useCallback(() => {
    setCustomizeCookies(!customizeCookiesToggleOn)
  }, [customizeCookiesToggleOn])

  React.useEffect(() => {
    if (state.savedAt !== null) {
      toggleModal()
    }

  }, [state.savedAt])

  if (!isBrowser || isCordova) {
    return null
  }

  let isDotDigitalPopup = false
  if (isModalOpen) {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  } else {
    document.getElementsByTagName("body")[0].style.overflow = "auto";

    if (state.audienceMeasurementCookiesOptedIn && enableDotDigitalPopup && popUpCode) {
      isDotDigitalPopup = true
    }
  }

  return (
    <CookiePolicyOuter isModalOpen={isModalOpen}>
      {<CookiePolicyInner isModalOpen={isModalOpen} customizeCookiesToggleOn={customizeCookiesToggleOn}>
        <CookieContent>
          {cookieOptInText
            ? <CookieParagraph>
              {contentfulService.renderRichText(cookieOptInText)}
            </CookieParagraph>
            : <>
              <CookieHeader>
                <CookieHeaderText>{translate('SECTION_ONE_TITLE')}</CookieHeaderText>
              </CookieHeader>

              <CookieUsageInformation>
                <CookieParagraph>
                  {translate('SECTION_ONE_PARAGRAPH_ONE')}
                </CookieParagraph>
                <CookieParagraph dangerouslySetInnerHTML={{
                  __html: translate('SECTION_ONE_PARAGRAPH_TWO', { openLinkTag: `<a href="${cookiePolicyLink}">`, closeLinkTag: `</a>` })
                }}
                />
              </CookieUsageInformation>
            </>}

          <CookieActionButton
            buttonType="primary"
            onClick={actions.acceptAllCookies}
          >
            {cookieOptInButtonText || translate('ACCEPT_ALL_COOKIES')}
          </CookieActionButton>

          <CookieActionButton
            buttonType="secondary"
            onClick={actions.rejectAllCookies}
          >
            {cookieRejectAllButtonText || translate('REJECT_ALL_COOKIES')}
          </CookieActionButton>

          {customizeCookiesToggleOn ?
            <>
              {cookiePreferencesText
                ? <CookieParagraph>
                  {contentfulService.renderRichText(cookiePreferencesText)}
                </CookieParagraph>
                : <>
                  <CookieHeader>
                    <CookieHeaderText>{translate('SECTION_TWO_TITLE')}</CookieHeaderText>
                  </CookieHeader>

                  <CookieUsageInformation>
                    <CookieParagraph>
                      {translate('SECTION_TWO_PARAGRAPH_ONE')}
                    </CookieParagraph>
                    <CookieParagraph>
                      {translate('SECTION_TWO_PARAGRAPH_TWO')}
                    </CookieParagraph>
                  </CookieUsageInformation>
                </>}

              <SeparateLine />

              {necessaryCookiesText
                ? <CookieParagraph>
                  {contentfulService.renderRichText(necessaryCookiesText)}
                </CookieParagraph>
                : <>
                  <CookieHeader>
                    <CookieHeaderText>{translate('SECTION_THREE_TITLE')}</CookieHeaderText>
                  </CookieHeader>

                  <CookieUsageInformation>
                    <CookieParagraph>
                      {translate('SECTION_THREE_PARAGRAPH_ONE')}
                    </CookieParagraph>
                  </CookieUsageInformation>
                </>}

              <SeparateLine />

              <CookieSubSectionWrapper>
                <ToggleButton ToggleOn={state.audienceMeasurementCookiesOptedIn} handleChange={actions.toggleAudienceMeasurement} />
                {audienceMeasurementText
                  ? <>
                    <CookieParagraph>
                      {contentfulService.renderRichText(audienceMeasurementText)}
                    </CookieParagraph>
                  </>
                  : <>
                    <CookieHeader>
                      <CookieHeaderText>{translate('SECTION_FOUR_PARAGRAPH_TWO_TITLE')}</CookieHeaderText>
                    </CookieHeader>

                    <CookieUsageInformation>
                      <CookieParagraph>
                        {translate('SECTION_FOUR_PARAGRAPH_TWO')}
                      </CookieParagraph>
                    </CookieUsageInformation>
                  </>}
              </CookieSubSectionWrapper>


              <CookieSubSectionWrapper>
                <ToggleButton ToggleOn={state.personalisedAdCookiesOptedIn} handleChange={actions.togglePersonalisedCookies} />
                {personalisedAdsText
                  ? <CookieParagraph>
                    {contentfulService.renderRichText(personalisedAdsText)}
                  </CookieParagraph>
                  : <>
                    <CookieHeader>
                      <CookieHeaderText>{translate('SECTION_FOUR_PARAGRAPH_THREE_TITLE')}</CookieHeaderText>
                    </CookieHeader>

                    <CookieUsageInformation>
                      <CookieParagraph>
                        {translate('SECTION_FOUR_PARAGRAPH_THREE')}
                      </CookieParagraph>
                    </CookieUsageInformation>
                  </>}
              </CookieSubSectionWrapper>

              <CookieActionButton
                buttonType="secondary"
                onClick={actions.savePreferences}
              >
                {saveMyPreferenceButtonText || translate('SAVE_MY_PREFERENCE')}
              </CookieActionButton>
            </> :
            <CookieActionButton
              buttonType="secondary"
              onClick={toggleCustomizeCookies}
            >
              {customiseNowButtonText || translate('CUSTOMIZE_NOW')}
            </CookieActionButton>
          }

        </CookieContent>

      </CookiePolicyInner>
      }
      {isModalOpen && <Overlay />}

      <CookieDrawerToggleButton
        buttonType="secondary"
        onClick={() => {
          if (isModalOpen) {
            actions.undoChanges()
          }
          toggleModal()
        }
        }
      >
        <AssetIcon svg={CookieSvg} size={isCordova || isMobileDevice ? 70 : 50} svgScale={1} />
      </CookieDrawerToggleButton>
      {isDotDigitalPopup && <DotDigitalPopup popUpCode={popUpCode} />}
    </CookiePolicyOuter>
  )
}

export default CookiePolicyWidgetLarge
