import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import { useSelector } from 'react-redux'
import {
  manyRequestSelector,
  centreSelector
} from 'src/store/resources/selectors'
import _filter from 'lodash/filter'
import _some from 'lodash/some'
import _lowerCase from 'lodash/lowerCase'
import _toLower from 'lodash/toLower'
import _startCase from 'lodash/startCase'
import _slice from 'lodash/slice'
import _isNaN from 'lodash/isNaN'
import _upperFirst from 'lodash/upperFirst'
import _groupBy from 'lodash/groupBy'
import _uniqBy from 'lodash/uniqBy'
import _reduce from 'lodash/reduce'
import _includes from 'lodash/includes'
import _forEach from 'lodash/forEach'
import _compact from 'lodash/compact'
import _castArray from 'lodash/castArray'
import _size from 'lodash/size'
import _flatMap from 'lodash/flatMap'
import _values from 'lodash/values'
import { getResources, getFields, getResourceId } from 'src/utility'
import _flow from 'lodash/flow'
import _mapValues from 'lodash/mapValues'
import _isArray from 'lodash/isArray'
import _orderBy from 'lodash/orderBy'
import _unionBy from 'lodash/unionBy'
import { getRequestKey } from './config'
import SearchSvg from 'src/assets/Search.svg'
import TextField from 'src/components/Fields/Text'
import Button from 'src/components/Button'
import { isBrowser, isCordova } from 'src/env'
import { useInternationalisation } from 'src/context/'

export const useAllNames = ({ request, clear, letter }) => {
  const {
    resources: unitResources,
    _status: unitsStatus,
    total: unitsTotal
  } = request
  const hasMore = unitsTotal && unitsTotal > _get(unitResources, 'length')

  return React.useMemo(() => {
    if (!unitsStatus.succeeded || clear) return []
    return _map(
      unitResources,
      (resource) => getFields(resource).name || getFields(resource).title
    )
  }, [unitResources, hasMore, letter])
}

export const useUnitsToDisplay = ({
  resources,
  letter,
  categoryFilter,
  filters,
  search,
  categoryModels,
  parentCategory,
  userCollection,
  attributes,
  categoryType,
  ordered
}) => {
  return React.useMemo(() => {
    const orderResources = ordered
      ? (x) => x
      : (resources) =>
          _orderBy(
            resources,
            [
              (resource) => {
                const { name, title, jobTitle } = getFields(resource)
                return _toLower(name || title || jobTitle).replace('the ', '')
              }
            ],
            ['asc']
          )

    if (
      !letter &&
      !_size(filters) &&
      !search &&
      !_some(categoryModels, 'groupBy') &&
      !userCollection &&
      !categoryType
    ) {
      return orderResources(resources)
    }
    
    const now = new Date().toISOString()
    let showResources = resources.filter((unit) => !unit.fields.endDate || unit.fields.endDate > now )
    const urlToType = {
      competitions: 'Competition',
      offers: 'Offer',
      rewards: 'Reward',
      jobs: 'Job'
    }
    if (userCollection) {
      const filterCriteria = _map(
        attributes[userCollection.key],
        userCollection.filterBy
      )
      showResources = _filter(showResources, ({ id }) =>
        _includes(filterCriteria, id)
      )
    }
    if (categoryType) {
      showResources = _filter(showResources, {
        fields: { categoryType: categoryType }
      })
    }
    if (_some(categoryModels, 'groupBy') && urlToType[parentCategory]) {
      const filterCriteria = _reduce(
        categoryModels,
        (memo, item) => {
          if (item.groupBy) return item.groupBy
          else return memo
        },
        ''
      )

      showResources = _filter(showResources, [
        filterCriteria,
        urlToType[parentCategory]
      ])
    }

    if (letter) {
      showResources = _filter(showResources, (resource) => {
        const unitName = getFields(resource).name
        const firstLetter = _lowerCase(_slice(unitName, 0, 1))
        if (_isNaN(parseInt(firstLetter, 10))) {
          return letter === firstLetter
        } else {
          return letter === '#'
        }
      })
    }
    if (categoryFilter) {
      const hasCategory = (categories, filterCategory) => {
        return _find(categories, (category) => _get(category, 'urlName') === filterCategory)
      }
      showResources = _filter(showResources, (resource) => {
        const fields = getFields(resource) || {}
        const categories = _map(_get(fields, 'categories'), getFields)
        return hasCategory(categories, categoryFilter)
      })
    }
    if (_some(filters)) {
      const filterIds = _compact(_values(filters))
      showResources = _filter(showResources, (resource) => {
        const fields = getFields(resource) || {}
        const ids = _compact(
          _flatMap(fields, (field) => {
            const get = Array.isArray(field) ? _map : _get
            return get(field, 'sys.id')
          })
        )
        return _some(ids, (id) => _includes(filterIds, id))
      })
    }
    if (search) {
      if (parentCategory === 'jobs') {
        const jobsByDescription = _filter(showResources, (resource) => {
          const fields = getFields(resource)
          let jobDescription = fields.jobDescription || ''
          return JSON.stringify(jobDescription)
            .toLowerCase()
            .includes(search.toLowerCase().trim())
        })

        const jobsByTitle = _filter(showResources, (resource) => {
          const fields = getFields(resource)
          let resourceTitle =
            fields.title || fields.name || fields.jobTitle || ''
          return resourceTitle
            .toLowerCase()
            .includes(search.toLowerCase().trim())
        })

        showResources = _unionBy(jobsByDescription, jobsByTitle)
      } else {
        showResources = _filter(showResources, (resource) => {
          const fields = getFields(resource)
          let resourceTitle =
            fields.title || fields.name || fields.jobTitle || ''
          return resourceTitle
            .toLowerCase()
            .includes(search.toLowerCase().trim())
        })
      }
    }

    return orderResources(showResources)
  }, [resources, letter, attributes, search, categoryModels])
}

export const useFilterSchema = ({
  categoryModels,
  enableSearch,
  enableShopOnlineButton,
  match,
  location,
  categoryResources,
  untranslateUrl,
  resources
}) => {
  const requests = _mapValues(categoryModels, (_, resourceType) => ({
    resourceType,
    requestKey: getRequestKey({
      location,
      match,
      isCategory: true,
      resourceType,
      untranslateUrl
    })
  }))
  const { translate, isFrenchCentre } = useInternationalisation()

  const getPrependSearchType = () => {
    const { pathname } = location
    switch (pathname) {
      case '/shop':
        return 'SHOPS'
      case '/dine':
        return 'EATERIES'
      case '/news':
        return 'NEWS'
      case '/events':
        return 'EVENTS'
      case '/offers':
        return 'OFFERS'
      case '/competitions':
        return 'COMPETITIONS'
      case '/promotions':
        return 'PROMOTIONS'
      case '/jobs':
        return 'JOBS'
      default:
        return ''
    }
  }

  const centre = useSelector(centreSelector())
  const categoryRequests = useSelector(manyRequestSelector(requests))
  if (categoryResources) {

    _forEach(categoryRequests, (category, categoryName) => {
      const categoryRequestResources = _get(categoryRequests, `${categoryName}.resources`, [])
      if (_size(categoryRequestResources)) {
        category.resources = categoryRequestResources
      }
    })
  }

  return React.useMemo(() => {
    const categoryHashmap = _mapValues(categoryRequests, getResources)
    const fields = []
    if (enableSearch) {
      fields.push({
        component: TextField,
        props: {
          label: translate('SEARCH', {
            item: _toLower(translate(getPrependSearchType()))
          }),
          iconSvg: SearchSvg
        },
        key: 'search',
        debounce: 300
      })
    }

    if (categoryModels) {
      fields.push(
        ..._map(
          categoryModels,
          ({ getSchema, groupBy = '', groupByMapping }, resourceType) => {
            let schema = getSchema()
            if (!getSchema) return null
            if (groupBy) {
              const createSchemaEntry = (resource) => {
                return groupByMapping
                  ? _startCase(
                      groupByMapping[_get(resource, groupBy, '').toLowerCase()]
                    )
                  : _get(resource, groupBy)
              }
              schema = getSchema(
                _uniqBy(
                  _map(categoryHashmap[resourceType], (resource) => ({
                    value: createSchemaEntry(resource),
                    label: _startCase(createSchemaEntry(resource))
                  })),
                  'value'
                )
              )
            } else {
              schema = getSchema(
                _map(categoryHashmap[resourceType], (resource) => {
                  const resouceId = getResourceId(resource)
                  const fields = getFields(resource)
                  return {
                    id: resouceId,
                    value: fields.urlName,
                    label: fields.title || fields.name
                  }
                }),
                resources
              )
            }
            return {
              ...schema,
              props: {
                ..._get(schema, 'props'),
                label: translate(_get(schema, 'props.label'))
              }
            }
          }
        )
      )
    }

    // to clear up confusion:
    // enableShopOnline comes from the CMS and is whether the CENTRE has shop online enabled
    // enableShopOnlineButton comes from the listings config,
    // eg: RetailUnit listings page has enableShopOnlineButton = true
    // but Jobs listings page has enableShopOnline = false
    const { enableShopOnline } = getFields(centre)
    if (enableShopOnline && enableShopOnlineButton && !isCordova) {
      fields.push({
        component: Button,
        props: {
          children: translate('SHOP_ONLINE'),
          buttonType: 'red',
          fullWidth: true,
          onClick: () => {
            if (isBrowser) {
              const href = '/eshop'
              window.location.href = href
            }
          }
        },
        key: 'shop-online',
        alignEnd: true
      })
    }

    return fields
  }, [categoryModels, categoryResources, enableSearch, centre])
}
