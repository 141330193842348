import React from 'react'
import _ from 'lodash'
import Listing from 'src/pages/Listing'
import RetailUnit from 'src/pages/RetailUnit'
import News from 'src/pages/News'
import Events from 'src/pages/Event'
import Promotions from 'src/pages/Promotion'
import Jobs from 'src/pages/Job'
import { getPageData } from 'src/components/Page'
import {
  fetchCategories,
  fetchTiles
} from './Listing/config'
import {
  fetchResources
} from 'src/store/resources/actionCreators'
import { useRequest } from 'src/store/resources/hooks'
import {
  getFields
} from 'src/utility'
import { getCentreConfig } from 'src/config'

/**
 *  Identify if the current url represents a listing page or a retail unit/promotion/event/news/job
 *  - shop/ (listing)
 *  - shop/fashion (listing)
 *  - shop/accessorize (retail unit)
 */

const RouteComponent = (props) => {
  const { parentCategory, id } = props.match.params
  const request = useRequest({
    resourceType: 'retailUnitCategory',
    requestKey: 'retailUnitCategory'
  })
  const allCategories = _.map(request.resources, resource => {
    return getFields(resource)
  })
  const isListingCategory = (id) => {
    const findCategory = _.find(allCategories, field => field.urlName === id)
    if (findCategory) {
      _.set(props, 'match.params.category', id)
      return true
    }
    return false
  }
  
  const isListingPage = !!isListingCategory(id)
  const renderComp = () => {
    switch (parentCategory) {
      case 'news':
      case 'magazine':
        return <News {...props} />
      case 'jobs':
      case 'offres-d-emploi': 
        return <Jobs {...props} />
      case 'events':
      case 'evenements':
        return <Events {...props} />
      case 'promotions':
      case 'offers':
      case 'offres':
      case 'competition':
      case 'competitions':
      case 'concours':
      case 'rewards':
      case 'cadeaux':
        return <Promotions {...props} />
      default:
        return <RetailUnit {...props} />
    }
  }
  return isListingPage ? <Listing {...props} /> : renderComp()
}

RouteComponent.getData = async (props) => {
  const { augmentWithApi = true } = getCentreConfig() || {}
  const { host, dispatch, location, match, locale } = props
  const { parentCategory, id } = match.params
  const params = {
    host,
    dispatch,
    location,
    match,
    locale
  }
  const matchResourceType = {
    events: 'event',
    evenements: 'event',
    offers: 'promotion',
    offres: 'promotion',
    competition: 'promotion',
    competitions: 'promotion',
    concours: 'promotion',
    rewards: 'promotion',
    cadeaux: 'promotion',
    magazine: 'news',
    jobs: 'job',
    'offres-d-emploi': 'job'
  }
  const contentResourceType = _.get(matchResourceType, [parentCategory], parentCategory)
  const now = new Date().toISOString()
  return Promise.all([
    dispatch(fetchResources({
      locale,
      resourceType: 'retailUnit',
      where: {
        'fields.urlName': id
      },
      requestKey: id,
      host
    })),
    fetchTiles(params),
    ...fetchCategories(params),
    getPageData(props, { urlName: parentCategory }),
    dispatch(fetchResources({
      locale,
      where: {
        ...(contentResourceType === 'job'
          ? { 'sys.id': id }
          : { 'fields.urlName' : id })
      },
      resourceType: contentResourceType,
      requestKey: id,
      host,
      include: 2,
      ...(contentResourceType === 'promotion' || contentResourceType === 'event'
      ? { augmentWithApi }
      : {})
    }))
  ])
}

RouteComponent.identifier = 'ROUTE'
export default RouteComponent
